import React, { Component } from 'react';
import classes from './Speech.module.css';

class Speech extends Component {
    state = {
        text: ''
    }

    handleRunterkommen = () => {
        console.log(this.props.zone + ": RUNTER KOMMEN");
        fetch('http://192.168.178.99:5005/' + this.props.zone + '/say/Bitte%20runter%20kommen%20-%20aber%20schnell/de-de');
    }

    handleEssenFertig = () => {
        console.log(this.props.zone + ": RUNTER KOMMEN");
        fetch('http://192.168.178.99:5005/' + this.props.zone + '/say/Essen%20ist%20fertig/de-de');
    }

    handleInput = () => {
        console.log(this.props.zone + ": " + this.state.text);
        fetch('http://192.168.178.99:5005/' + this.props.zone + '/say/' + this.state.text + '/de-de');
    }

    render() {
        return (
            <div className={classes.Speech}>
                <button className={classes.Button}
                    onClick={this.handleRunterkommen}>
                    Runter kommen
                </button>
                <button className={classes.Button}
                    onClick={this.handleEssenFertig}>
                    Essen fertig
                </button>
                <input className={classes.Button} id="text" type="text" value={this.state.text} onChange={(event) => this.setState({ text: event.target.value })} />
                <button className={classes.Button}
                    onClick={this.handleInput}>
                    Say
                </button>
            </div>
        )
    }
}

export default Speech;