import React from 'react';
import styles from './ZoneButton.module.css';

const zoneButton = (props) => (
  <button className={styles.Button}
    disabled={props.selected}
    onClick={props.onClick}>
    {props.label}
  </button>
)


export default zoneButton;