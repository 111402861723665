import React from 'react';
import Layout from './hoc/Layout/Layout';
import SonosControl from './containers/SonosControl/SonosControl';
import NotificationContainer from 'react-notifications';
import 'react-notifications/lib/notifications.css';

function App() {
  return (
    <div>
      <NotificationContainer />
      <Layout>
        <SonosControl />
      </Layout>
    </div>
  );
}

export default App;
