import React from 'react';
import ZoneButton from '../../UI/ZoneButton/ZoneButton';
import classes from './Zones.module.css';

const allZones = [
    { label: 'Maikl', id: 'maikl' },
    { label: 'Laura', id: 'laura' },
    { label: 'Lukas', id: 'lukas' },
    { label: 'Moni', id: 'moni' },
];

const zones = (props) => (
    <header className={classes.Zones}>
        {
            allZones.map(zone => (
                <ZoneButton
                    key={zone.id}
                    label={zone.label}
                    selected={props.currentZone === zone.id}
                    onClick={() => props.selectorHandler(zone.id)} />
            ))
        }
    </header>
)

export default zones;