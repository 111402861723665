import React, { Component } from 'react';
import Aux from '../../hoc/Aux/Aux';

import Zones from '../../components/Sonos/Zones/Zones';
import Controls from '../../components/Sonos/Controls/Controls';
import Playlists from '../../components/Sonos/Playlists/Playlists';
import Speech from '../../components/Sonos/Speech/Speech';


class SonosControl extends Component {
  state = {
    zone: 'maikl'
  }

  zoneSelectorHandler = (newZone) => {
    this.setState(state => ({
      zone: newZone
    }));
  }

  render() {

    return (
      <Aux>
        <Zones currentZone={this.state.zone} selectorHandler={this.zoneSelectorHandler} />
        <Controls zone={this.state.zone} />
        <Playlists zone={this.state.zone} />
        <Speech zone={this.state.zone} />
      </Aux>
    );
  }
}

export default SonosControl;