import React, { Component } from 'react';
import NotificationManager from 'react-notifications';
import Control from '../../UI/Control/Control';
import styles from './Controls.module.css';
import '../../../assets/styles/iconStyle.css';

class Controls extends Component {
    state = {
        play: true
    }

    handlePlayPause = () => {
        const newStatePlay = !this.state.play;

        this.setState(state => ({
            play: newStatePlay
        }));

        console.log(this.props.zone + ": " + (newStatePlay ? "PLAY" : "PAUSE"));
        fetch('http://192.168.178.99:5005/' + this.props.zone + '/' + (newStatePlay ? 'play' : 'pause'))
            .then(function (response) {
                if (response.ok) {
                    NotificationManager.success(newStatePlay ? "PLAY" : "PAUSE")
                } else {
                    NotificationManager.error('Fehler bei der Aktion ' + newStatePlay ? "PLAY" : "PAUSE")
                }
            });

    }

    handlePrev = () => {
        console.log(this.props.zone + ": PREV");
        fetch('http://192.168.178.99:5005/' + this.props.zone + '/previous')
            .then(function (response) {
                if (response.ok) {
                    NotificationManager.success('Previous')
                } else {
                    NotificationManager.error('Fehler bei der Aktion PREVIOUS')
                }
            });
    }

    handleNext = () => {
        console.log(this.props.zone + ": NEXT");
        fetch('http://192.168.178.99:5005/' + this.props.zone + '/next')
            .then(function (response) {
                if (response.ok) {
                    NotificationManager.success('Next')
                } else {
                    NotificationManager.error('Fehler bei der Aktion NEXT')
                }
            });
    }

    handleVolUp = () => {
        console.log(this.props.zone + ": VOL UP");
        fetch('http://192.168.178.99:5005/' + this.props.zone + '/volume/+5');
    }

    handleVolDown = () => {
        console.log(this.props.zone + ": VOL DOWN");
        fetch('http://192.168.178.99:5005/' + this.props.zone + '/volume/-5');
    }
    render() {
        return (
            <div className={styles.Controls}>
                <Control icon="icon-previous" onClick={this.handlePrev} />
                <Control icon={this.state.play ? "icon-pause" : "icon-play2"} onClick={this.handlePlayPause} />
                <Control icon="icon-next" onClick={this.handleNext} />
                <Control icon="icon-volume-low" onClick={this.handleVolDown} />
                <Control icon="icon-volume-high" onClick={this.handleVolUp} />
            </div>
        );
    }
}

export default Controls;



