import React, { Component } from 'react';
import NotificationManager from 'react-notifications';
import classes from './Playlists.module.css';


class Playlists extends Component {
    state = {
        favorites: [],
        playlists: []
    };


    componentDidMount() {
        fetch('http://192.168.178.99:5005/favorites/detailed')
            .then(res => res.json())
            .then((data) => {
                this.setState({ favorites: data })
            })
            .catch(console.log)

        fetch('http://192.168.178.99:5005/playlists/detailed')
            .then(res => res.json())
            .then((data) => {
                this.setState({ playlists: data })
            })
            .catch(console.log)


    }

    startFavorite(listName) {
        console.log(this.props.zone + ': Starte Favorite ' + listName)
        fetch('http://192.168.178.99:5005/' + this.props.zone + '/favorite/' + listName)
            .then(function (response) {
                if (response.ok) {
                    NotificationManager.success('Favorite ' + listName + ' gestartet.')
                } else {
                    NotificationManager.error('Fehler beim starten von ' + listName)
                }
            });

    }

    startPlaylist(listName) {
        console.log(this.props.zone + ': Starte Playlist ' + listName)
        fetch('http://192.168.178.99:5005/' + this.props.zone + '/playlist/' + listName)
            .then(function (response) {
                if (response.ok) {
                    NotificationManager.success('Playlist ' + listName + ' gestartet.')
                } else {
                    NotificationManager.error('Fehler beim starten von ' + listName)
                }
            });
    }

    render() {
        return (
            <div className={classes.Playlists}>
                {
                    this.state.favorites.map((favorite) => (
                        <div key={favorite.title} onClick={() => this.startFavorite(favorite.title)} className={classes.Playlist}>
                            <img src={favorite.albumArtUri ? favorite.albumArtUri : 'http://spotify-static-resources.s3.amazonaws.com/imgstub/playlist_folder_legacy.png'} alt={favorite.title} width='100%' /><br />
                            {favorite.title}
                        </div>
                    ))
                }

                {
                    this.state.playlists.map((playlist) => (
                        <div key={playlist.title} onClick={() => this.startPlaylist(playlist.title)} className={classes.Playlist}>
                            <img src={'http://192.168.178.39:1400' + playlist.albumArtUri} alt={playlist.title} width='100%' /><br />
                            {playlist.title}
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default Playlists;